.title {
	font-size: 1.23529rem;
	line-height: 1.381;
	font-weight: 400;
	letter-spacing: 0.011em;
	color: #1d1d1f;
	display: inline;
	vertical-align: middle;
}
.content {
	color: #6e6e73;
	margin-top: 3px;
	max-width: 48.05882rem;
}

.card {
	&:hover {
		transition: all 0.3s ease-in-out;
		transform: scale(1.05);
	}
}
